import { Component, createResource, Show } from 'solid-js'
import styles from '~/components/pro-subscription-schedule/pro-subscription-schedule.module.scss'
import { getFullDate } from '~/helpers/dates'
import { translations } from '~/translations'
import { getPlans } from '~/services/stripe/plans'
import { formatRecurrence } from '~/services/stripe/prices'
import RightIcon from '~/assets/icons/arrow-right.svg'
import { ProSubscriptionScheduleProps } from '~/components/pro-subscription-schedule/pro-subscription-schedule.interfaces'

const ProSubscriptionSchedule: Component<ProSubscriptionScheduleProps> = ({ subscriptions }) => {
  const [plans] = createResource(getPlans)
  const planLabel = (priceId: string) => {
    let name = ''
    let recurrence = ''
    const allPlans = [...plans.latest?.entreprisePlans ?? [], ...plans.latest?.individualPlans ?? []]
    allPlans.forEach(plan => {
      const price = plan.prices.find(price => price.id === priceId)
      if (price) {
        name = plan.plan.title
        recurrence = formatRecurrence(price)
      }
    })
    return `${name} /${recurrence}`
  }

  return (
    <div class={styles.schedule}>
      {/* Default subscription */}
      <Show when={!subscriptions[0].cancelled && !subscriptions[0].schedule}>
        <div class={styles.title}>
          {translations().subscriptions.renewal.nextPayment}
        </div>

        <div class={styles.phase}>
          <div class={styles.name}>
            {subscriptions[0].plan.title}
          </div>
          <div class={styles.date}>
            {getFullDate(subscriptions[0].periodEnd)}
          </div>
        </div>
      </Show>

      {/* Cancelled state */}
      <Show when={subscriptions[0].cancelled}>
        <div class={styles.phase}>
          <div class={styles.name}>
            {translations().subscriptions.renewal.endsOn}
          </div>
          <div class={styles.date}>
            {getFullDate(subscriptions[0].periodEnd)}
          </div>
        </div>
      </Show>

      {/* Scheduled subscription change */}
      <Show when={subscriptions[0].schedule}>
        <div class={styles.title}>
          {translations().subscriptions.renewal.schedule}
        </div>

        <div class={styles.phases}>
          {subscriptions[0].schedule?.phases.map((entry, index) => (
            <>
              {index > 0 && (
                <RightIcon class={styles.arrow} />
              )}
              <div class={styles.phase}>
                <div class={styles.items}>
                  {entry.items.map(item => (
                    <div class={styles.item}>
                      <span class={styles.label}>
                        {planLabel(item.price.toString())}
                      </span>
                      <span class={styles.quantity}>
                        x {item.quantity}
                      </span>
                    </div>
                  ))}
                </div>
                <div class={styles.date}>
                  {getFullDate(new Date(entry.end_date * 1000))}
                </div>
              </div>
            </>
          ))}
        </div>
      </Show>
    </div>
  )
}

export default ProSubscriptionSchedule

import { Component, createResource, createSignal, Show } from 'solid-js'
import styles from '~/components/account-subscriptions/account-subscriptions.module.scss'
import EmptyState from '~/components/empty-state/empty-state'
import IndividualSubscription from '~/components/individual-subscription/individual-subscription'
import { cancelSubscription, getFormattedSubscriptions } from '~/services/stripe/subscription'
import EmptyIcon from '~/assets/icons/document-empty.svg'
import CancelIcon from '~/assets/icons/delete.svg'
import ProSubscriptions from '~/components/pro-subscriptions/pro-subscriptions'
import Button from '~/components/button/button'
import { useNavigate } from '@solidjs/router'
import { translations } from '~/translations'
import ProSubscriptionSchedule from '~/components/pro-subscription-schedule/pro-subscription-schedule'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { getFullDate } from '~/helpers/dates'
import { setLoadingState } from '~/services/loading/loading'

const AccountSubscriptions: Component = () => {
  const navigate = useNavigate()
  const [subscriptions, { refetch: refetchSubs }] = createResource(getFormattedSubscriptions)
  const individualSubscription = () => subscriptions.latest?.individual
  const proSubscriptions = () => subscriptions.latest?.pro ?? []

  const goToIndividualSubscription = () => {
    navigate('/subscribe')
  }
  const goToProSubscriptions = () => {
    navigate('/subscribe/pro')
  }

  // Cancel Modal
  const cancelTitle = translations().subscriptions.pro.cancelConfirmation.title
  const cancelDescription = () => {
    let description = ''
    const proSubs = proSubscriptions()
    if (proSubs.length > 0){
      description = translations().subscriptions.pro.cancelConfirmation.description(getFullDate(proSubs[0].periodEnd as Date))
    }
    return description
  }
  const [showCancelConfirmation, setCancelConfirmation] = createSignal(false)
  const onConfirmCancel = async () => {
    setCancelConfirmation(true)
  }
  const onSubscriptionCancel = async () => {
    const subscription = proSubscriptions()[0]
    if (subscription){
      setLoadingState({
        loading: true
      })
      await cancelSubscription(subscription.id)
      refetchSubs()
      setLoadingState({
        loading: false
      })
      setCancelConfirmation(false)
    }
  }

  return (
    <div class={styles.accountSubscriptions}>
      <ConfirmationModal
        title={cancelTitle}
        description={cancelDescription()}
        open={showCancelConfirmation()}
        setOpen={setCancelConfirmation}
        onConfirm={onSubscriptionCancel}
        type='danger'
      />

      <div class={styles.section}>
        <header class={styles.sectionHead}>
          <div class={styles.sectionTitle}>{translations().subscriptions.indie.title}</div>
          {!individualSubscription() && (
            <Button
              style='purple'
              onClick={goToIndividualSubscription}
            >
              {translations().subscriptions.indie.subscribe.cta}
            </Button>
          )}
        </header>
        <Show when={individualSubscription()} fallback={
          <EmptyState
            icon={EmptyIcon}
            label={translations().subscriptions.indie.emptyState}
            border
          />
        }>
          {subscription => (
            <IndividualSubscription
              subscription={subscription()}
              refetch={refetchSubs}
            />
          )}
        </Show>
      </div>

      <div class={styles.section}>
        <header class={styles.sectionHead}>
          <div class={styles.sectionTitle}>{translations().subscriptions.pro.title}</div>

          <Button
            style='purple'
            onClick={goToProSubscriptions}
          >
            {proSubscriptions().length > 0 ? translations().subscriptions.pro.upgrade.cta : translations().subscriptions.pro.subscribe.cta}
          </Button>

          <Button
            icon={CancelIcon}
            style='negative'
            onClick={onConfirmCancel}
          >
            {translations().general.actions.stop}
          </Button>
        </header>
        <Show
          when={subscriptions.latest?.pro && subscriptions.latest.pro.length > 0}
          fallback={
            <EmptyState
              icon={EmptyIcon}
              label={translations().subscriptions.pro.emptyState}
              border
            />
          }
        >
          {subscriptions.latest?.pro && (
            <ProSubscriptionSchedule subscriptions={subscriptions.latest.pro} />
          )}
          <ProSubscriptions subscriptions={proSubscriptions()} />
        </Show>
      </div>
    </div>
  )
}

export default AccountSubscriptions

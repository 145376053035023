import { Component } from 'solid-js'
import { ProSubscriptionHeadProps } from '~/components/pro-subscription-head/pro-subscription-head.interfaces'
import styles from '~/components/pro-subscription-head/pro-subscription-head.module.scss'
import { plans } from '~/static/stripe'
import { translations } from '~/translations'

const ProSubscriptionHead: Component<ProSubscriptionHeadProps> = ({ licensesGroup }) => {
  const plan = plans.pro.find(plan => plan.productId === licensesGroup.productId)
  
  return (
    <div class={styles.proSubscriptionHead}>
      <div class={styles.name}>
        <div class={styles.brand}>
          {plan?.brand}
        </div>
        <div class={styles.title}>
          {plan?.title}
        </div>
        {plan?.subtitle && (
          <div class={styles.subtitle}>
            {plan?.subtitle}
          </div>
        )}
      </div>
      <div class={styles.licenses}>
        <div class={styles.count}>
          {licensesGroup.totalSlots ?? 0}
        </div>
        <div class={styles.label}>
          {translations().subscriptions.pro.licenses.count(licensesGroup.totalSlots)}
        </div>
      </div>
    </div>
  )
}

export default ProSubscriptionHead

import { Component, createResource } from 'solid-js'
import LicenseManager from '~/components/license-manager/license-manager'
import ProSubscriptionHead from '~/components/pro-subscription-head/pro-subscription-head'
import { ProSubscriptionsProps } from '~/components/pro-subscriptions/pro-subscriptions.interfaces'
import styles from '~/components/pro-subscriptions/pro-subscriptions.module.scss'
import { getLicenses } from '~/services/stripe/license'

const ProSubscriptions: Component<ProSubscriptionsProps> = () => {
  const [licensesCollections, { refetch }] = createResource(getLicenses)

  return (
    <div class={styles.proSubscriptions}>
      {licensesCollections.latest?.map(licensesGroup => (
        <div class={styles.subscriptionEntry}>
          <ProSubscriptionHead licensesGroup={licensesGroup} />
          <LicenseManager
            licensesGroup={licensesGroup}
            refetch={refetch}
          />
        </div>
      ))}
    </div>
  )
}

export default ProSubscriptions

import { Component, createResource, Show } from 'solid-js'
import styles from '~/components/subscription-schedule/subscription-schedule.module.scss'
import { getFullDate } from '~/helpers/dates'
import { translations } from '~/translations'
import { getPlans } from '~/services/stripe/plans'
import { formatRecurrence } from '~/services/stripe/prices'
import RightIcon from '~/assets/icons/arrow-right.svg'
import { SubscriptionScheduleProps } from '~/components/subscription-schedule/subscription-schedule.interfaces'

const SubscriptionSchedule: Component<SubscriptionScheduleProps> = ({ subscription }) => {
  const [plans] = createResource(getPlans)
  const planLabel = (priceId: string) => {
    let name = ''
    let recurrence = ''
    const allPlans = [...plans.latest?.entreprisePlans ?? [], ...plans.latest?.individualPlans ?? []]
    allPlans.forEach(plan => {
      const price = plan.prices.find(price => price.id === priceId)
      if (price) {
        name = plan.plan.title
        recurrence = formatRecurrence(price)
      }
    })
    return `${name} /${recurrence}`
  }

  return (
    <div class={styles.schedule}>
      {/* Default subscription */}
      <Show when={!subscription.cancelled && !subscription.schedule}>
        <div class={styles.title}>
          {translations().subscriptions.renewal.nextPayment}
        </div>

        <div class={styles.phase}>
          <div class={styles.name}>
            {subscription.plan.title}
          </div>
          <div class={styles.date}>
            {getFullDate(subscription.periodEnd)}
          </div>
        </div>
      </Show>

      {/* Cancelled state */}
      <Show when={subscription.cancelled}>
        <div class={styles.phase}>
          <div class={styles.name}>
            {translations().subscriptions.renewal.endsOn}
          </div>
          <div class={styles.date}>
            {getFullDate(subscription.periodEnd)}
          </div>
        </div>
      </Show>

      {/* Scheduled subscription change */}
      <Show when={subscription.schedule}>
        <div class={styles.title}>
          {translations().subscriptions.renewal.schedule}
        </div>

        <div class={styles.phases}>
          {subscription.schedule?.phases.map((entry, index) => (
            <>
              {index > 0 && (
                <RightIcon class={styles.arrow} />
              )}
              <div class={styles.phase}>
                <div class={styles.name}>
                  {planLabel(entry.items[0].price.toString())}
                </div>
                <div class={styles.date}>
                  {getFullDate(new Date(entry.end_date * 1000))}
                </div>
              </div>
            </>
          ))}
        </div>
      </Show>
    </div>
  )
}

export default SubscriptionSchedule

import AccountSubscriptions from '~/components/account-subscriptions/account-subscriptions'
import Header from '~/components/header/header'

export default function Subscriptions() {
  return (
    <>
      <Header />
      <AccountSubscriptions />
    </>
  )
}
